<template>
  <div class="summary-swiper">
    <div class="summary-swiper__container">
      <Swiper
        class="summary-swiper__slider"
        :navigation="{
          nextEl: '.summary-swiper__btn-next',
          prevEl: '.summary-swiper__btn-prev',
        }"
        :thumbs="{ swiper: thumbsSwiper }"
        :pagination="isMobile"
        :grab-cursor="true"
        :zoom="true"
        :loop="true"
        :modules="[
          SwiperNavigation,
          SwiperPagination,
          SwiperThumbs,
          SwiperZoom,
        ]"
      >
        <SwiperSlide
          v-for="(image, index) in product.getImages('large')"
          :key="index"
        >
          <div class="swiper-zoom-container">
            <img
              :src="image"
              :alt="product.getName() + ' - Изображение ' + (index + 1)"
              loading="lazy"
            />
          </div>
        </SwiperSlide>
        <ClientOnly>
          <div v-if="product.isOrderable" class="summary-swiper__labels">
            <LabelsNameplate
              v-for="label in product.labels"
              :key="label"
              :label="label"
            />
          </div>
          <Badges :product="product" :size="100" mobile-column />
          <HeaderActions :product="product" class="summary-swiper__actions" />
          <div class="summary-swiper__zoom">
            <img
              src="~~/assets/icons/zoom.svg"
              alt="zoom icon"
              class="summary-swiper__zoom-icon"
            />
            <span class="summary-swiper__zoom-caption">
              Zoom on doubleclick
            </span>
          </div>
        </ClientOnly>
        <CarouselNavigation class-name="summary-swiper" />
      </Swiper>
      <Swiper
        class="summary-swiper__thumbnails"
        :slides-per-view="6"
        direction="horizontal"
        :navigation="{
          nextEl: '.summary-swiper-thumbnails__btn-next',
          prevEl: '.summary-swiper-thumbnails__btn-prev',
        }"
        :modules="[SwiperNavigation]"
        @swiper="setThumbsSwiper"
      >
        <SwiperSlide
          v-for="(image, index) in product.getImages('small')"
          :key="index"
          class="summary-swiper__thumbnail-img-w"
        >
          <!-- Нужен перевод для слова Изображение -->
          <img
            :src="image"
            :alt="product.getName() + ' - Изображение ' + (index + 1)"
            class="summary-swiper__thumbnail-img"
          />
        </SwiperSlide>
        <CarouselNavigation class-name="summary-swiper-thumbnails" />
      </Swiper>
    </div>
    <ul
      v-if="product.isOrderable && !getIsPoliciesFetching"
      class="summary-swiper__benefits"
    >
      <template v-for="benefit in benefits" :key="benefit.title">
        <li v-if="benefit.isShown?.() ?? true" class="summary-swiper__benefit">
          <img
            :src="benefit.image"
            :alt="benefit.title"
            class="summary-swiper__benefit-image"
            loading="lazy"
          />
          <span class="summary-swiper__benefit-title">
            {{ _T(benefit.title) }}
          </span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";
import { useHeaderStore } from "~/store/headerStore";
import LabelsNameplate from "~/modules/shared/nameplate/LabelsNameplate.vue";
import { addScopedEventListener } from "~/utils/eventListener";
import HeaderActions from "~/modules/shared/productCard/components/HeaderActions.vue";
import { usePaymentPolicyStore } from "~/store/paymentPolicyStore";
import Badges from "~/modules/shared/productCard/components/Badges.vue";
import CarouselNavigation from "~/modules/shared/sliders/ui/CarouselNavigation.vue";

const props = defineProps({
  product: { type: Product, required: true },
  navigationHeight: { type: Number, required: false },
});

onMounted(() => {
  const debounced = useDebounceFn(() => {
    getMobile();
  }, 100);

  nextTick(() => {
    getMobile();
  });

  addScopedEventListener(window, "resize", debounced);
});

const { width: windowSize } = useWindowSize();

const headerStore = useHeaderStore();
const { getHeightHeader } = storeToRefs(headerStore);

const paymentPolicyStore = usePaymentPolicyStore();
const { getPrivatBankPayment, getIsPoliciesFetching } =
  storeToRefs(paymentPolicyStore);

useAsyncData("paymentPolicyStore", () =>
  paymentPolicyStore.fetch().then(() => true),
);

const benefits = [
  {
    image: "/img/free-delivery.svg",
    title: "@Free shipping",
    isShown: () => isFreeDelivery(props.product),
  },
  {
    image: "/img/partpay_icon.svg",
    title: "@Pay Parts",
    isShown: () =>
      getPrivatBankPayment.value(
        "payParts",
        props.product.getPrice(Product.PRICE_TYPE.CURRENT),
      ).isAvailable,
  },
  {
    image: "/img/benefit-warranty.svg",
    title: "@Manufacturer quality assurance",
  },
];

const thumbsSwiper = ref(null);
const isMobile = ref(false);

const headerHeight = () => {
  return getHeightHeader.value.header;
};

const setThumbsSwiper = (swiper) => {
  thumbsSwiper.value = swiper;
};

const getMobile = () => {
  isMobile.value = windowSize.value <= 1024;
};
</script>

<style lang="scss">
.swiper-pagination {
  & > .swiper-pagination-bullet-active {
    background: var(--color-primary-base);
  }
}
</style>

<style scoped lang="scss">
.swiper-slide-thumb-active {
  & .summary-swiper__thumbnail-img {
    border: 2px solid var(--color-primary-base);
  }
}

.summary-swiper {
  position: sticky;

  --space-top: 16px;
  top: calc(
    v-bind(navigationHeight) * 1px + var(--space-top) + v-bind("headerHeight()")
  );

  max-width: 680px;
  width: 50%;

  @include flex-container(column, flex-start);
  gap: 32px;

  @include mobile {
    position: relative;

    top: auto;
    max-width: 500px;
    width: 100%;

    align-self: center;
  }

  &__container {
    width: 100%;

    @include flex-container(column, flex-start);
    gap: 16px;
  }

  &__thumbnails {
    width: 100%;

    &.swiper {
      @include bigMobile {
        display: none;
      }
    }
  }

  &__thumbnail-img-w {
    cursor: pointer;

    &.swiper-slide {
      width: 68px;
    }
  }

  &__slider,
  &__thumbnail-img {
    border: 1px solid var(--color-sky-light);
    border-radius: 8px;

    @include mobile {
      border: none;
    }
  }

  &__slider {
    position: relative;

    width: 100%;
  }

  &__slider-prev-btn,
  &__slider-next-btn {
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 10;

    @include fixedHW(40px);

    @include flex-container(column, center, center);

    border-radius: 50%;
    background-color: var(--color-sky-lighter);

    transition: box-shadow 0.1s;

    @include bigMobile {
      right: -19px;
      bottom: 50%;
      transform: translateX(-50%);
    }

    &:hover {
      @extend %shadow;
    }
  }

  &__slider-prev-btn {
    right: 72px;

    @include bigMobile {
      left: 21px;
    }
  }

  &__labels {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 10;

    @include flex-container(column, flex-start);
    gap: 8px;

    @include mobile {
      top: 0;
      left: 0;
    }
  }

  &__actions {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;

    @include mobile {
      top: 0;
      right: 0;
    }
  }

  &__zoom {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);

    pointer-events: none;

    z-index: 10;

    @include flex-container(row, flex-start, center);
    gap: 4px;

    @include font(12, 16);
    color: var(--color-blue-base-status);

    @include mobile {
      display: none;
    }
  }

  &__zoom-icon {
    @include fixedHW(16px);
  }

  &__benefits {
    max-width: 530px;
    width: 100%;

    @include flex-container(row, center, flex-start);
    gap: 16px;
    align-self: center;
  }

  &__benefit {
    max-width: 178px;

    @include flex-container(row, center, center);
    gap: 8px;

    @include bigMobile {
      max-width: 100px;
      flex-direction: column;
    }
  }

  &__benefit-image {
    @include fixedHW(40px);
  }

  &__benefit-title {
    @include font(14, 18);

    @include bigMobile {
      @include font(12, 16);
      text-align: center;
    }
  }
}
</style>
