<template>
  <div class="summary-configuration">
    <div
      v-for="configuration in configurationPresenter(configurations)"
      :key="configuration.id"
    >
      <p class="summary-configuration__name">
        {{ getLocalizedName(configuration.name) }}:
      </p>
      <div class="summary-configuration__option">
        <Component
          :is="product.isEqual(option.product) ? 'span' : 'ProductLink'"
          v-for="option in getSortedOptions(configuration.options)"
          :key="option.id"
          :product="product.isEqual(option.product) ? null : option.product"
          :class="[
            { active: product.isEqual(option.product) },
            option.type,
            { outOfStock: !option.product.isActive() },
            { hidden: getIsHiddenConfiguration(configuration.id) },
          ]"
          class="ga-product-options"
          :style="{ '--color-bg': option.parameters?.hex }"
        >
          {{ "color" !== option.type ? getLocalizedName(option.value) : null }}
        </Component>
        <button
          v-if="shouldBeHidden(configuration.options)"
          class="summary-configuration__option-button"
          aria-label="show more configurations"
          type="button"
          @click.prevent="
            setIsHiddenConfiguration(
              configuration.id,
              !getIsHiddenConfiguration(configuration.id),
            )
          "
        >
          {{
            getIsHiddenConfiguration(configuration.id)
              ? `${_T("@More")} +${
                  Object.keys(configuration.options).length - 3
                }`
              : _T("@Hide")
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";
import { useHiddenConfiguration } from "~/uses/useHiddenConfiguration";

const props = defineProps({
  product: { type: Product, required: true },
  configurations: { type: [Array, null], required: false },
});

const {
  getIsHiddenConfiguration,
  buildHiddenConfigurations,
  setIsHiddenConfiguration,
  shouldBeHidden,
} = useHiddenConfiguration();

const currentConfiguration = (configurations) => {
  return configurations.find(({ product }) => {
    return props.product.isEqual(product);
  });
};

const configurationPresenter = (configurations) => {
  const configurationPresenter = {};

  configurations.forEach((configuration) => {
    configuration.dimensions.forEach((dimension) => {
      if (!configurationPresenter.hasOwnProperty(dimension.id)) {
        configurationPresenter[dimension.id] = {
          id: dimension.id,
          name: dimension.name,
          options: {},
        };
      }

      if (
        !configurationPresenter[dimension.id].options.hasOwnProperty(
          dimension.option.id,
        )
      ) {
        const getOptionProduct = configurations.find((configuration) =>
          configuration.dimensions.every((configurationDimension) => {
            if (configurationDimension.id === dimension.id) {
              return configurationDimension.option.id === dimension.option.id;
            }

            return (
              currentConfiguration(configurations)?.dimensions.find(
                (currentDimension) =>
                  currentDimension.id === configurationDimension.id,
              ).option?.id === configurationDimension.option.id
            );
          }),
        )?.product;

        if (undefined !== getOptionProduct) {
          configurationPresenter[dimension.id].options[dimension.option.id] = {
            ...dimension.option,
            product: new Product(getOptionProduct),
          };
        }
      }
    });
  });

  return configurationPresenter;
};

const getSortedOptions = (options) => {
  const formatToNumber = (value) =>
    getLocalizedName(value).replace(/^\D*(\d+).*/, "$1");

  return Object.values(options).sort(
    (a, b) => formatToNumber(a.value) - formatToNumber(b.value),
  );
};

watch(
  [() => props.configurations],
  () => {
    buildHiddenConfigurations(configurationPresenter(props.configurations));
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.summary-configuration {
  @include flex-container(row, flex-start, center);
  flex-wrap: wrap;
  gap: 24px;

  border-top: 1px solid var(--color-sky-base);

  padding-top: 24px;

  @include mobile {
    @include flex-container(column, flex-start, flex-start);

    padding-top: 16px;
  }

  &__name {
    @include font(14, 18);

    margin-bottom: 8px;
  }

  &__option {
    @include flex-container(row, flex-start, center);
    flex-wrap: wrap;
    gap: 8px;

    & .regular {
      position: relative;

      @include font(12, 16);

      outline: 1px solid var(--color-sky-light);
      border-radius: 30px;

      padding: 4px 8px;

      transition: outline 0.2s;
      cursor: pointer;

      &:not(.active) {
        &:not(.outOfStock):hover {
          background-color: var(--color-primary-lightest);
          outline-color: var(--color-primary-light);
        }

        &:hover {
          outline-color: var(--color-sky-base);
        }
      }

      &.active {
        color: white;
        background-color: var(--color-primary-base);
        outline-color: var(--color-primary-base);
      }

      &.outOfStock:not(.active) {
        background-color: var(--color-sky-light);
        color: var(--color-sky-base);
      }
    }

    & .color {
      --color-bg: white;

      position: relative;
      z-index: 1;

      width: 22px;
      height: 22px;

      background-color: var(--color-bg);
      border-radius: 50%;

      cursor: pointer;

      &:not(.active) {
        outline: 2px solid var(--color-sky-base);

        &:not(.outOfStock):hover {
          outline-color: var(--color-primary-light);
        }
      }

      &.active::after {
        content: "";
        position: absolute;
        z-index: 10;

        top: -3px;
        left: -3px;

        width: 28px;
        height: 28px;

        border-radius: 50%;
        border: 2px solid var(--color-primary-base);
      }

      &.outOfStock:not(.active):before {
        content: "";
        display: block;

        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 2;

        width: 22px;
        height: 1px;

        background-color: var(--color-sky-base);
        transform: rotate(135deg) translate(-52%, -50%);
        transform-origin: 0;
      }
    }

    & .hidden:nth-child(n + 4) {
      display: none;
    }
  }

  &__option-button {
    @include font(12, 16);
    text-align: center;
    color: white;

    border-radius: 24px;
    background-color: #fdaa00;

    padding: 4px 16px;
  }
}
</style>
