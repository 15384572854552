<template>
  <section class="summary-description product__container">
    <h2 class="summary-description__title">
      <span class="summary-description__title-prepend">
        {{ _T("@Description") }}
      </span>
      <span class="summary-description__product-name">
        {{ product.getName() }}
      </span>
    </h2>
    <div class="summary-description__text-w" :class="{ hidden: !isShown }">
      <div
        v-if="undefined !== product.getDescription()"
        ref="textRef"
        class="ui-rich-text"
        v-html="product.getDescription()"
      />
    </div>
    <button
      v-if="textHeight >= textVisibleHeight"
      class="go-to summary-description__button"
      :class="{ isShown }"
      @click="isShown = !isShown"
    >
      <span>
        {{
          isShown
            ? _T("@Product hide all description")
            : _T("@Product show all description")
        }}
      </span>
      <img
        src="@/assets/icons/nav-arrow-down.svg"
        alt="arrow down"
        loading="lazy"
      />
    </button>
    <ReferenceProductCarousel :product="product" />
  </section>
</template>

<script setup>
import { Product } from "~/models/product.model";
import ReferenceProductCarousel from "~/modules/shared/sliders/productCarousels/ReferenceProductCarousel.vue";

const props = defineProps({
  product: { type: Product, required: true },
});

const textRef = ref(null);
const isShown = ref(false);
const textVisibleHeight = 300;

const { height: textHeight } = useElementSize(textRef, {
  height: 0,
});

const calcTextMaxHeight = () => {
  if (textHeight.value < textVisibleHeight) {
    return textHeight.value;
  }

  return textVisibleHeight;
};
</script>

<style lang="scss" scoped>
.summary-description {
  border-radius: 16px;

  @include mobile {
    border-radius: 0;
  }

  &__title {
    @include font(24, 28, 700);

    @include mobile {
      @include font(18, 24, 600);
    }
  }

  &__title-prepend {
    margin-right: 4px;
  }

  &__text-w {
    height: auto;

    overflow: hidden;

    &.hidden {
      height: calc(v-bind("calcTextMaxHeight()") * 1px);
    }
  }

  &__button {
    max-width: max-content;

    @include flex-container();
    gap: 8px;

    &.isShown {
      & > img {
        rotate: z 180deg;
      }
    }
  }
}
</style>
